import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  wrapper: {
    backgroundColor: ({ theme }) => theme.palette.themeColor,
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  profile: {
    width: ({ isNavbarOpen }) => `calc(100% - ${isNavbarOpen ? "25%" : "12%"})`,
    marginTop: "4rem",
    marginLeft: ({ isNavbarOpen }) => (isNavbarOpen ? "22rem" : "12rem"),
    marginRight: "4rem",
    marginBottom: "8rem",
    padding: 10,
    borderRadius: 8,
    boxShadow: "0px 5px 20px 0px rgba(0, 0, 0, 1)",
    backgroundColor: ({ theme }) => theme.palette.bgColor,
    minHeight: '58vh',
    alignItems: 'center',

    "@media (max-width: 599px)": {
      width: ({ isNavbarOpen }) =>
        `calc(100% - ${isNavbarOpen ? "44%" : "38 %"})`,
    },

    "@media (min-width: 600px) and (max-width: 899px)": {
      width: ({ isNavbarOpen }) =>
        `calc(100% - ${isNavbarOpen ? "38%" : "35%"})`,
    },
    "@media (min-width: 900px) and (max-width: 1199px)": {
      width: ({ isNavbarOpen }) =>
        `calc(100% - ${isNavbarOpen ? "20%" : "12%"})`,
      marginLeft: ({ isNavbarOpen }) => (isNavbarOpen ? "18rem" : "12rem"),
    },
  },
  info: {
    marginTop: "7%",
    color: ({ theme }) => theme.palette.text.primary,
  },
  avatar: {
    width: "88px",
    height: "88px",
    borderRadius: "50%",
  },
  code: {
    borderRadius: "5px",
    outline: "0.5px solid #fff",
    textAlign: "left",

    "@media (min-width: 0px) and (max-width: 899px)": {
      marginTop: "2rem",
    },
  },
});
