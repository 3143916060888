import { createRef, useEffect, useState } from "react";
import hljs from "highlight.js/lib/core";
import json from "highlight.js/lib/languages/json";
import "highlight.js/styles/monokai-sublime.css";
import { useStyles } from "./styles";
import { useTheme } from "@emotion/react";

const Highlight = ({ language = "json", children }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const codeNode = createRef();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (language === "json" && !hljs.getLanguage(language)) {
      hljs.registerLanguage("json", json);
      setLoaded(true);
    }
  }, [language]);

  useEffect(() => {
    if (loaded && codeNode.current) {
      hljs.highlightElement(codeNode.current);
    }
  }, [loaded, codeNode]);

  return (
    <pre className={classes.profile} data-testid="highlight">
      <code ref={codeNode} className={language} data-testid="codeBlock">
        {children}
      </code>
    </pre>
  );
};

export default Highlight;
