import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  loadingIcon: {
    color: ({ theme }) => theme.palette.mode === 'light' ? "secondary" : "#9a0143",
  },
  loadingText: {
    color: ({ theme }) => theme.palette.text.primary,
    marginTop: '1%'
  }
})