import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  webplayerBtn: {
    backgroundColor: ({ theme }) => theme.palette.button.primary,
    fontSize: "11px",
    color: ({ theme }) => theme.palette.text.primary,
    border: "2px solid #A8ABC2",
    fontWeight: 700,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  webplayerWrapper: {
    width: "100%",
    height: "auto",
    backgroundColor: "#fff",
    marginTop: "2rem",
    marginBottom: "5rem",
    boxShadow: "0px 5px 20px 0px rgba(0, 0, 0, 0.2)",
    borderRadius: "10px",
  },
  iframeWrapper: {
    backgroundColor: "#fff",
    borderBottomRightRadius: "10px",
    borderBottomLeftRadius: "10px",
  },
  backBtn: {
    marginRight: "2%",
    backgroundColor: "#8588A1",
    fontWeight: 700,
    fontSize: "12px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#8588A1",
    },
  },
  btnContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  backIcon: {
    marginBottom: "1px",
  },
  iframe: {
    width: "100%",
    padding: ({ isWebPlayerExpanded }) =>
      isWebPlayerExpanded ? " 0px" : "10px",
    height: "100vh",
  },
  expandIcon: {
    backgroundColor: "#8588A1",
    "&:hover": {
      backgroundColor: "#8588A1",
    },
    color: "#fff",
    width: "30px",
    height: "35px",
  },
  parametricContainer: {
    display: ({ openPlayer }) => (openPlayer ? "none" : "flex"),
  },
  tableWrapper: {
    height: "58vh",
    width: "100%",
    marginBottom: "4rem",
    marginTop: "2rem",
    "@media (max-height: 700px)": {
      height: "100%",
      marginTop: "0rem",
    },
  },

  tidNameWrapper: {
    display: "flex",
    width: "80%",
    alignItems: "center",
    textAlign: "left",
  },
  shipLogo: {
    height: "27px",
    width: "27px",
  },
  loginButton: {
    marginTop: "10px",
    cursor: "pointer",
    color: "rgb(255, 255, 255)",
    fontSize: "12px",
    fontWeight: 500,
    backgroundColor: "rgb(133, 136, 161)",
    padding: "10px 16px",
    boxShadow:
      "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
    minWidth: "64px",
    border: "none",
    borderRadius: "4px",
    textTransform: "uppercase",
  },
  loginLauncher: {
    backgroundColor: "rgb(255, 255, 255)",
    borderRadius: "10px",
    padding: "20px",
    width: "500px",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 5px 20px 0px",
  },
  loadingContainer: {
    marginTop: "10%",
    display: "flex",
    justifyContent: "center",
  },
  tidName: {
    marginTop: 5,
    marginLeft: "0.7rem",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
  },
  webplayerHeaderGrid: {
    paddingRight: "1%",
    paddingLeft: "1.5%",
    paddingTop: "2%",
  },
  detailsHeader: {
    marginLeft: "0.8vw",
  },
});
