import { Box, Typography, Tooltip } from "@mui/material";
import { FaArrowDown, FaSortDown } from "react-icons/fa6";
import clsx from "clsx";
import RenderSortIcons from "../utils/sortIcons";
import { headers } from "./utils";

const DateHeadColumn = ({ sortBy, sortOrder, column, classes }) => {
  return (
    <Box className={classes.dateHeaderWrapper}>
      <Typography className={clsx(classes.dateHeader, classes.themeColor)}>
        {column.title}
      </Typography>
      {sortBy === column.key ? (
        RenderSortIcons(sortOrder, classes)
      ) : (
        <Tooltip title="Sort" arrow>
          <Box
            component={"span"}
            className={classes.placeholderArrowIcon}
            data-testid="initialSortArrow"
          >
            <FaArrowDown />
          </Box>
        </Tooltip>
      )}
    </Box>
  );
};

const DurationHeadColumn = ({ sortBy, sortOrder, column, classes }) => {
  return (
    <Box className={classes.durationHeaderWrapper}>
      <Box className={classes.durationSortWrapper}></Box>
      <Box className={classes.durationSortIconsWrapper}>
        {sortBy === column.key && sortOrder !== "initial" ? (
          RenderSortIcons(sortOrder, classes)
        ) : (
          <Box component={"span"} data-testid="initialSortArrow">
            <FaSortDown />
          </Box>
        )}
      </Box>
    </Box>
  );
};

const DateHeaders = ({
  showHideList,
  classes,
  toggleSortOrder,
  sortOrder,
  sortBy,
}) => {
  const dateHeaders = [
    {
      key: "LotNumber",
      title: "Lot No.",
      show: true,
      className: classes.dateHeadTitle,
      render: (column) => (
        <Box
          sx={{
            minWidth:
              !showHideList.yield &&
              !showHideList.dates &&
              !showHideList.duration &&
              "100%",
            maxWidth:
              !showHideList.yield &&
              !showHideList.dates &&
              !showHideList.duration &&
              "100%",
          }}
          className={clsx(
            classes.dateHeaderWrapper,
            classes.themeBackgroundColor
          )}
        >
          <Typography className={clsx(classes.dateHeader, classes.themeColor)}>
            {column.title}
          </Typography>
          {sortBy === column.key ? (
            RenderSortIcons(sortOrder, classes)
          ) : (
            <Tooltip title="Sort" arrow>
              <Box
                component={"span"}
                className={classes.placeholderArrowIcon}
                data-testid="initialSortArrow"
              >
                <FaArrowDown />
              </Box>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  const dateHeadersMap = headers.map((header) => {
    header.className =
      header.type === "dates"
        ? classes.dateHeadTitle
        : classes.durationHeadTitle;
    header.show =
      header.type === "dates" ? showHideList.dates : showHideList.duration;
    header.render =
      header.type === "dates"
        ? (column) => (
            <DateHeadColumn
              sortBy={sortBy}
              sortOrder={sortOrder}
              column={column}
              classes={classes}
            />
          )
        : (column) => (
            <DurationHeadColumn
              sortBy={sortBy}
              sortOrder={sortOrder}
              column={column}
              classes={classes}
            />
          );
    return header;
  });
  dateHeadersMap.forEach((header) => dateHeaders.push(header));

  return (
    <>
      {dateHeaders.map(
        (column, index) =>
          column.show && (
            <Box
              data-testid={`columnHead-${column.key}`}
              className={clsx(
                column.className,
                index === 0 && classes.stickyHeader
              )}
              onClick={() => column.show && toggleSortOrder(column.key)}
              key={column.key}
            >
              {column.render(column)}
            </Box>
          )
      )}
    </>
  );
};

export default DateHeaders;
