import { createUseStyles } from "react-jss";
export const useStyles = createUseStyles({
  wrapper: {
    marginInline: "auto",
    left: ({ isNavbarOpen }) => (isNavbarOpen ? "7%" : "2%"),
    height: 100,
    width: 800,
    backgroundColor: ({ theme }) => theme.palette.bgColor,
    borderRadius: "8px",
    position: 'relative',
    transition: 'left 0.4s',
  },
  textWrapper: {
    textAlign: "center",
  },
  text: {
    color: ({ theme }) => theme.palette.text.primary,
  },
  emptyStateWrapper: {
    minHeight: '680px',
    backgroundColor: ({ theme }) => theme.palette.themeColor,
    display: 'flex',
    alignItems: 'center'
  }
});
