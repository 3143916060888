export default function loadAuth(loadData) {
  let timer = setTimeout(() => {
    loadData();
    clearTimeout(timer);
  })
}

export async function getToken({
  getAccessTokenSilently,
  loginWithRedirect
}) {
  try {
    return await getAccessTokenSilently()
  } catch (e) {
    const currentUrl = window.location.href
    loginWithRedirect({
      appState: { returnTo: currentUrl }
    })
  }
}
