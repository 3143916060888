import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

//Possible values for severity: "error", "warning", "info", "success"

const Notification = ({ message, open, setOpen, severity = "success" }) => {
  const handleClose = (event, reason) => {
    setOpen(false);
  };

  return (
    <Snackbar
      data-testid="notification"
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity} variant="filled">
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
