import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    wrapper: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: '40%',
        borderRadius: '8px',
        padding: '8px 16px',
        backgroundColor: ({ theme }) => theme.palette.bgColor,
        outline: 'none',
    },
    text: {
        paddingBottom: 2,
        color: ({ theme }) => theme.palette.text.primary,
    },
    options: {
        color: ({ theme }) => theme.palette.text.primary,
        marginRight: 0
    },
    title: {
        paddingBottom: '8px',
        marginTop: '8px',
        color: ({ theme }) => theme.palette.text.primary,
    },
    btn: {
        color: ({ theme }) => theme.palette.mode === 'light' ? "#9a0143" : "#fff",
        fontFamily: 'Rubik',
        '&:hover': {
            backgroundColor: ({ theme }) => theme.palette.mode === 'light' ? 'rgba(128, 128, 128, 0.2)' : "#9a0143",
        }
    },
    checkbox: {
        color: ({ theme }) => theme.palette.mode === "light" ? "rgb(118, 118, 118)" : "#fff",
        stroke: 'rgb(118, 118, 118)',
        strokeWidth: '0.1px',
        "&.Mui-checked": {
            color: "#9a0143",
        },
        "&.MuiCheckbox-root": {
            zIndex: "1",
        },
        "&.MuiCheckbox-root.Mui-checked::before": {
            content: "''",
            width: "10px",
            height: "10px",
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            background: "#fff",
            zIndex: "-1",
        },
        "& .MuiSvgIcon-root": {
            fontSize: '1.2rem'
        }
    }
})