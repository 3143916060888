import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import HomePage from "pages/homePage";
import UserProfile from "pages/userProfile";
import SupplyAndDemand from "pages/supplyDemand";
import Emptystate from "components/emptyState";
import constants from "../constants/constants.json";
import { useEffect } from "react";
import { setNavigate } from "utils/history";

export const AppRoutes = ({ isNavbarOpen }) => {
  const navigate = useNavigate();

  useEffect(() => {
    setNavigate(navigate);
  }, [navigate]);
  return (
    <Routes>
      <Route path={"/"} element={<HomePage isNavbarOpen={isNavbarOpen} />} />
      <Route
        path={"/profile"}
        element={<UserProfile isNavbarOpen={isNavbarOpen} />}
      />
      <Route
        path="/overview/entry/:pid/:fid?/:tid?/*"
        element={<SupplyAndDemand isNavbarOpen={isNavbarOpen} />}
      />
      <Route
        path="/unauthorized"
        element={
          <Emptystate
            title={constants.unauthorized}
            message={constants.unauthorizedMessage}
            isNavbarOpen={isNavbarOpen}
          />
        }
      />
      <Route
        path="/error"
        element={
          <Emptystate
            title={constants.internalServerError}
            message={constants.internalServerErrorMessage}
            isNavbarOpen={isNavbarOpen}
          />
        }
      />
      <Route
        path="/notfound"
        element={
          <Emptystate
            title={constants.notFound}
            message={constants.notFoundMessage}
            isNavbarOpen={isNavbarOpen}
          />
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
