import { baseUrl, webPlayerBaseUrl, spotfireBaseUrl, overViewUrl } from 'config/settings';
const GWAPI = '/glasswall-api';
const helvetiaOverview = '/helvetia-data';
const helvetiaDetailsView = helvetiaOverview + '/component';
const helvetiaTaskView = helvetiaOverview + '/task';
const amkorLotDataView = '/amkor-lot-data';
const systemEdcParser = '/system-edc-parser';

export const $apis = {
    authCookie: webPlayerBaseUrl + '/api/authcookie',
    summaryTable: baseUrl + GWAPI,
    mapView: baseUrl + GWAPI,
    overviewLevel1: overViewUrl + GWAPI,
    overviewLevel2: overViewUrl + GWAPI,
    overviewLevel3: overViewUrl + GWAPI,
    helvetiaOverview: baseUrl + helvetiaOverview,
    helvetiaDetailsView: baseUrl + helvetiaDetailsView,
    helvetiaTaskView: baseUrl + helvetiaTaskView,
    amkorLotDataView: baseUrl + amkorLotDataView,
    systemEdcParser: baseUrl + systemEdcParser
}

export const $views = {
    spotfireAPI: webPlayerBaseUrl + '/spotfire/wp/GetJavaScriptApi.ashx?Version=7.11',
    s3Explorer: 'https://adv-glasswall-fe-public.s3.eu-central-1.amazonaws.com/filesexplorer.html',
    wpTmplUrl: spotfireBaseUrl + '/render/2_Op3RslUC3w4gEc06/analysis?file=',
    reporting: 'https://app.powerbi.com/reportEmbed?reportId=3f46008d-adf4-4cf4-aa63-a137199b4bc9&autoAuth=true&ctid=b0de9dff-4833-4d4f-b442-1e0dbfae2ec8&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXdlc3QtdXMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D'
}
