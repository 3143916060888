import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  pageContainer: {
    width: "100%",
    padding: "3rem 0",
  },
  headerWrapper: {
    margin: "auto auto 20px",
  },
  actionContainer: {
    width: "50%",
    gap: "4%",
    display: "flex",
  },
  title: {
    color: ({ theme }) => theme.palette.text.primary,
    display: "flex",
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "37.5px",
    width: "40%",
  },
  titleGrid: { width: "50%" },
  btn: {
    backgroundColor: ({ theme }) => theme.palette.bgColor,
    color: ({ theme }) => theme.palette.text.primary,
    fontSize: "14px !important",
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    lineHeight: "150% !important",
    width: "9vw",
    border: "1px solid #a8abc2",
    borderRadius: "5px",
    display: "flex",
    boxShadow: "none",
    textTransform: "none",
    justifyContent: "flex-start",
    height: "3.5vh",
  },
  dateBtn: {
    width: "11vw",
    minWidth: "210px !important",
    minHeight: "32px",
    maxHeight: '42px'
  },
  showHide: {
    minWidth: "165px !important",
    minHeight: "32px",
    maxHeight: '42px',
    fontFamily: 'Rubik'
  },
  headerGroupWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "3%",
  },
  input: {
    position: "relative",
  },
  icons: {
    height: "1.8rem",
    width: "1.8rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#5b5d6e",
    borderRadius: "5px",
    cursor: "pointer",
    color: "#fff",
    border: "none",
    "&:hover": {
      backgroundColor: "#5b5d6e",
      opacity: "0.9",
    },
  },
  arrowIcon: {
    right: "0px",
    position: "absolute",
  },
  calendarAnchor: {
    marginTop: "-60px",
    position: "absolute",
    left: "56%",
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
    zIndex: "100",
  },
  columnsAnchor: {
    marginTop: "-60px",
    position: "absolute",
    left: "90%",
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
    zIndex: "100",
  },
  active: {
    transform: "translateY(0)",
    transition: "400ms ease",
    opacity: 1,
    visibility: "visible",
  },
  inactive: {
    transform: "translateY(-30px)",
    transition: "400ms ease",
    opacity: 0,
    visibility: "hidden",
  },
  btnWrapper: {
    display: "flex",
    gap: "10%",
    justifyContent: "flex-end",
    alignItems: "center",
    marginLeft: "1%",
  },
});
