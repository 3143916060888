import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useStyles } from "./styles";
import { useTheme } from "@mui/material";

const LoadingSpinner = ({ loadingMessage, marginLeft = "10%" }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <Box
      sx={{ marginLeft: marginLeft }}
      className={classes.wrapper}
      data-testid={"container"}
    >
      <CircularProgress className={classes.loadingIcon} color="secondary" />
      <div className={classes.loadingText} data-testid={"message"}>
        {loadingMessage || ""}
      </div>
    </Box>
  );
};

export default LoadingSpinner;
