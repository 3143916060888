import { useTheme } from "@emotion/react";
import Plot from "react-plotly.js";
import { getTimeStamp } from "utils/tools";

let modeBarButtons = [
  [
    "toImage",
    "zoom2d",
    "pan2d",
    "autoScale2d",
    "zoomIn2d",
    "zoomOut2d",
    "resetScale2d",
    //"hoverClosestCartesian", "hoverCompareCartesian",  "toggleSpikelines",
  ],
];

function getColor(duration) {
  const colors = {
    ReceiptBumpDuration: "#0D559E",
    BumpDuration: "#C7000C",
    BumpProbeDuration: "#0D559E",
    ProbeDuration: "#F47240",
    ProbeAssemblyDuration: "#0D559E",
    AssemblyDuration: "#E3A915",
    AssemblyTestDuration: "#0D559E",

    TestDuration: "#1BC39B",
    TestShipDuration: "#0D559E",
    GridLines: "rgba(68, 68, 68, 0.5)",
  };
  return colors[duration];
}
const durations = [
  "Receipt Bump Duration",
  "Bump Duration",
  "Bump Probe Duration",
  "Probe Duration",
  "Probe Assembly Duration",
  "Assembly Duration",
  "Assembly Test Duration",
  "Test Duration",
  "Test Ship Duration",
];

const PlotDuration = (props) => {
  const theme = useTheme();
  const isDarkTheme = useTheme().palette.mode === "dark";
  // if there are no data, return null
  if (props?.tableRowsPerPage.length === 0) {
    return null;
  }
  if (!props?.x) {
    return null;
  }

  function getBarWidth(maxItems) {
    return maxItems < 10 ? 0.08 * maxItems : undefined;
  }

  const layout = {
    yaxis: {
      ...(isDarkTheme && {
        gridcolor: getColor("GridLines"),
        zeroline: true,
        zerolinecolor: getColor("GridLines"),
      }),

      title: {
        text: "Duration (days)",
        standoff: 50,
        font: { color: theme.palette.text.primary },
      },
    },
  }

  return (
    <Plot
      data-testid="plotDuration"
      useResizeHandler
      style={{ width: "100%", height: 450 }}
      data={[
        {
          name: "Total Duration",
          x: props.x,
          y: props.tableRowsPerPage.map((lot) => lot["TotalDuration"]),
          type: "scatter",
          mode: "lines+markers",
          marker: { color: theme.palette.text.secondary },
        },
        ...durations.map((duration) => {
          const name = duration;
          duration = duration.split(" ").join("");
          return {
            type: "bar",
            name,
            x: props.x,
            y: props.tableRowsPerPage.map((lot) => lot[duration]),
            marker: { color: getColor(duration) },
            width: getBarWidth(props.x.length),
          };
        }),
      ]}
      layout={{
        paper_bgcolor: theme.palette.bgColor,
        plot_bgcolor: theme.palette.bgColor,
        legend: {
          indentation: 50,
          orientation: "v",
          font: {
            color: theme.palette.text.secondary,
          },
          xanchor: "left",
          yanchor: "center",
        },
        title: {
          text: "Lot Duration",
          font: { color: theme.palette.text.primary },
        },
        font: {
          color: theme.palette.text.primary,
        },
        barmode: "stack",
        xaxis: {
          title: {
            text: "Lot IDs",
            standoff: 50,
            font: { color: theme.palette.text.primary },
          },
        },
        yaxis: layout.yaxis
      }}
      config={{
        scrollZoom: false,
        displaylogo: false,
        modeBarButtons,
        responsive: true,
        toImageButtonOptions: {
          format: "png",
          filename: `LotStatusTrackingDurationChart_${getTimeStamp()}`,
        },
      }}
    />
  );
};

export default PlotDuration;
