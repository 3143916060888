/*
 * @Description: axios request method
 * @Version: 1.0
 */

import axios from 'axios';
import { baseUrl, timeout } from 'config/settings';
import { navigateTo } from './history';

/**
 * axios defaults headers config
 */

axios.defaults.baseURL = baseUrl; // request url
axios.defaults.timeout = timeout; // response time
axios.defaults.headers.get['Content-Type'] = 'application/json; charset=utf-8';

/**
 * @description: POST JSON Params Interceptor
 * @param {type}
 * @return {Promise}
 */
axios.interceptors.request.use((config) => {
  // config.responseType = 'text';

  // Check API URL
  if (config.url.indexOf('://') !== -1) config.baseURL = '';

  // Authorization json web token
  return config;
}, (error) => {
  return Promise.reject(new Error(error));
});

/**
 * @description: response interceptors
 * @param {type}
 * @return {Promise}
 */
axios.interceptors.response.use((res) => {
  //data manipulation 
  return res;
}, (error) => {
  console.log('error', error)
  if (error?.response?.status === 401 || error?.response?.status === 403) {
    return navigateTo("/unauthorized")
  }
  else if (error?.response?.status === 404) {
    return navigateTo("/notFound")
  }
  else if (error?.response?.status === 500) {
    return navigateTo("/error")
  }
  return Promise.reject(new Error(error.response));
});

/**
 * @description: FETCH request axios request
 * @param {String}
 * @return {Promise}
 */
export function $fetch(url, params = {}, token = null) {
  axios.defaults.headers.Authorization = `Bearer ${token}`;
  return new Promise((resolve, reject) => {
    axios.get(url, { params },)
      .then(response => {
        return resolve(response)
      }).catch(error => {
        return reject(new Error(error))
      })
  })
}

/**
 * @description: POST request method
 * @param {String}
 * @return {Promise}
 */
export function $post(url, params = {}, token = null) {
  axios.defaults.headers.Authorization = `Bearer ${token}`;
  return new Promise((resolve, reject) => {
    axios.post(url, params)
      .then(response => {
        return resolve(response)
      }).catch(error => {
        return reject(new Error(error))
      })
  })
}

/**
 * @description: PATCH request method
 * @param {String}
 * @return {Promise}
 */
export function $patch(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios.patch(url, params)
      .then(response => {
        return resolve(response)
      }).catch(error => {
        return reject(new Error(error))
      })
  })
}

/**
 * @description: PUT request method
 * @param {String}
 * @return {Promise}
 */
export function $put(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios.put(url, params)
      .then(response => {
        return resolve(response)
      }).catch(error => {
        return reject(new Error(error))
      })
  })
}
