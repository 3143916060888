import { useStyles } from "./styles";
import { useTheme } from "@emotion/react";
import {
  DataGrid,
  GridPagination,
  useGridApiContext,
  useGridSelector,
  gridPageCountSelector,
} from "@mui/x-data-grid";
import clsx from "clsx";
import MuiPagination from "@mui/material/Pagination";
import PropsTypes from "prop-types";
import LoadingSpinner from "components/loadingSpinner";
import constants from "../../constants/constants.json";

function Pagination({ page, onPageChange, className }) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      className={className}
      count={pageCount}
      siblingCount={0}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
    />
  );
}

function CustomPagination(props) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}

function Loading() {
  return <LoadingSpinner loadingMessage={constants.loading} marginLeft={"0"} />;
}

const BasicTable = ({
  columns,
  rows,
  disableColumnMenu,
  isNavbarOpen,
  isLoading = false,
  disableVirtualization = false,
  style,
  showScroller = "hidden",
  paddingX = "30px",
  sxProps,
  sortModel = null,
  onSortModelChange = () => {},
  paginationModel = { page: 0, pageSize: 10 },
  onPaginationModelChange = () => {},
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme, isNavbarOpen });

  return (
    <DataGrid
      disableVirtualization={disableVirtualization}
      data-testid={"data-grid"}
      sx={{
        ...sxProps,
        "& .MuiTablePagination-root": {
          width: "100%",
        },

        "& .MuiTablePagination-actions": {
          position: "absolute",
          right: "48%",
        },

        "& .MuiTablePagination-displayedRows": {
          marginRight: "30px",
        },

        "& .MuiDataGrid-row": {
          borderTop: "2px solid #A8ABC2",
          borderBottom: "none",
          outline: "none",
        },
        "& .MuiDataGrid-main": {
          paddingX: paddingX,
        },
        "& .MuiDataGrid-cell": {
          borderBottom: 0,
        },
        ".MuiDataGrid-footerContainer": {
          border: "none",
        },
        ".MuiDataGrid-virtualScroller": {
          overflowX: showScroller,
          overflowY: "auto",
        },
        "& .MuiDataGrid-columnHeaders": {
          textTransform: "uppercase",
        },
        "& .MuiDataGrid-row:hover": {
          backgroundColor: "transparent",
        },
        ".MuiDataGrid-cell:focus": {
          outline: "none !important",
        },
        "& .MuiDataGrid-columnHeader:focus": {
          outline: "none !important",
        },
        "& .MuiDataGrid-columnHeader:focus-within": {
          outline: "none !important",
        },
        ".MuiDataGrid-cell:focus-within": {
          outline: "none !important",
        },
        ".MuiDataGrid-row--borderBottom": {
          background: `${theme.palette.bgColor} !important`,
        },
      }}
      className={clsx(classes.table, style)}
      rows={rows}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel,
        },
      }}
      {...(sortModel ? { sortModel } : null)}
      pageSizeOptions={[10, 25, 50, 100]}
      onSortModelChange={onSortModelChange}
      sortingOrder={["desc", "asc"]}
      disableColumnMenu={disableColumnMenu}
      disableRowSelectionOnClick
      loading={isLoading}
      onPaginationModelChange={onPaginationModelChange}
      pagination
      slots={{
        pagination: CustomPagination,
        loadingOverlay: Loading,
      }}
    />
  );
};

Pagination.prototype = {
  page: PropsTypes.number,
  onPageChange: PropsTypes.func,
  className: PropsTypes.any,
};

CustomPagination.prototype = {
  props: PropsTypes.any,
};

BasicTable.prototype = {
  columns: PropsTypes.any,
  rows: PropsTypes.any,
  disableColumnMenu: PropsTypes.bool,
  isNavbarOpen: PropsTypes.bool,
  isLoading: PropsTypes.bool,
  disableVirtualization: PropsTypes.bool,
  style: PropsTypes.any,
  showScroller: PropsTypes.string,
  paddingX: PropsTypes.string || PropsTypes.number,
  sxProps: PropsTypes.object,
};

export default BasicTable;
