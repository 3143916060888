import { Typography, Box } from "@mui/material";
import { useStyles } from "./styles";
import { useTheme } from "@emotion/react";

export default function Emptystate({ title, message, isNavbarOpen }) {
  const theme = useTheme();
  const classes = useStyles({ theme, isNavbarOpen });
  return (
    <Box className={classes.emptyStateWrapper}>
      <Box sx={{ px: 4, py: 2, boxShadow: 24 }} className={classes.wrapper}>
        <Box className={classes.textWrapper}>
          <Typography
            data-testid="emptyStateTitle"
            className={classes.text}
            variant="h5"
          >
            {title || ""}
          </Typography>
          <Typography
            data-testid="emptyStateMessage"
            className={classes.text}
            variant="body2"
          >
            {message || ""}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
