import { useTheme } from "@emotion/react";
import Plot from "react-plotly.js";
import { getTimeStamp } from "utils/tools";

const gridLineColor = "rgba(68, 68, 68, 0.5)";

let modeBarButtons = [
  [
    "toImage",
    "zoom2d",
    "pan2d",
    "autoScale2d",
    "zoomIn2d",
    "zoomOut2d",
    "resetScale2d",
    //"hoverClosestCartesian", "hoverCompareCartesian",  "toggleSpikelines",
  ],
];

function getBarWidth(maxItems) {
  return maxItems < 10 ? 0.08 * maxItems : undefined;
}

const PlotQuantity = (props) => {
  const theme = useTheme();
  const isDarkTheme = useTheme().palette.mode === "dark";

  const takaDRatioColor = isDarkTheme ? "#ffff66" : "00008B"
  const layout = {
    yaxis: {
      ...(isDarkTheme && {
        gridcolor: gridLineColor,
        zeroline: true,
        zerolinecolor: gridLineColor,
      }),
      title: {
        text: "Quantity (MCM)",
        standoff: 40,
        font: { color: theme.palette.text.primary },
      },
    },
    yaxis2: {
      ...(isDarkTheme && {
        gridcolor: gridLineColor,
        zeroline: true,
        zerolinecolor: gridLineColor,
      }),
      title: {
        text: "TakaD-Ratio (%)",
        standoff: 40,
        font: { color: theme.palette.text.primary },
      },
      overlaying: "y1",
      side: "right",
      zeroline: false,
    },
  }

  // if there are no data, return null
  if (props?.tableRowsPerPage?.length === 0) {
    return null;
  }

  if (!props?.x) {
    return null;
  }

  const barWidth = getBarWidth(props.x.length)

  return (
    <Plot
      data-testid="plotQuantity"
      useResizeHandler
      style={{ width: "100%", height: 450 }}
      data={[
        {
          name: "Total MCM",
          x: props.x,
          y: props.tableRowsPerPage.map((lot) => lot["TestOutDie"]),
          xaxis: "x",
          yaxis: "y",
          type: "scatter",
          mode: "lines+markers",
          marker: { color: theme.palette.text.secondary },
        },
        {
          name: "TakaD-Ratio",
          x: props.x,
          y: props.tableRowsPerPage.map((lot) => lot["TakaDRatio"]),
          xaxis: "x",
          yaxis: "y2",
          type: "scatter",
          mode: "markers",
          marker: {
            color: takaDRatioColor,
          },
        },
        {
          type: "bar",
          name: "Final MCM - TakaD",
          x: props.x,
          y: props.tableRowsPerPage.map((lot) => lot["TestOutDieM"]),

          marker: { color: "#F47240" },
          width: barWidth,
        },
        {
          type: "bar",
          name: "Final MCM - Taka",
          x: props.x,
          y: props.tableRowsPerPage.map((lot) => lot["TestOutDieN"]),
          marker: { color: "1BC39B" },
          width: barWidth,
        },
      ]}
      layout={{
        showlegend: true,
        legend: {
          indentation: 50,
          orientation: "v",
          x: 1,
          y: 1.12,
          xanchor: "left",
          yanchor: "center",
          font: {
            color: theme.palette.text.secondary,
          },
        },
        font: {
          color: theme.palette.text.primary,
        },

        paper_bgcolor: theme.palette.bgColor,
        plot_bgcolor: theme.palette.bgColor,
        title: "Lot Quantities",
        barmode: "stack",
        xaxis: {
          title: {
            text: "Lot IDs",
            font: { color: theme.palette.text.primary },
          },
        },
        yaxis: layout.yaxis,
        yaxis2: layout.yaxis2
      }}
      config={{
        scrollZoom: false,
        displaylogo: false,
        modeBarButtons,
        responsive: true,
        toImageButtonOptions: {
          format: "png",
          filename: `LotStatusTrackingQuantitiesChart_${getTimeStamp()}`,
        },
      }}
    />
  );
};

export default PlotQuantity;
