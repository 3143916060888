import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, ThemeProvider, createTheme } from "@mui/material";
import { getDesignTokens } from "theme/theme";
import Header from "components/common/header";
import Footer from "components/common/footer";
import { useAuth0 } from "@auth0/auth0-react";
import { AppRoutes } from "Route";
import { BrowserRouter } from "react-router-dom";
import { AppProvider } from "context/appContext";
import NavBar from "components/common/navBar/index";
import "./App.scss";
import axios from "axios";
import { getToken } from "utils/loadAuth";



function App() {
  const [currentTheme, setCurrentTheme] = useState(() => {
    let savedTheme = localStorage.getItem("theme");
    if (!savedTheme) {
      localStorage.setItem("theme", "light");
      savedTheme = "light";
    }
    return savedTheme;
  });
  const scrollRef = useRef(null);
  const [isNavBarOpen, setIsNavBarOpen] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [isAuthLoaded, setIsAuthLoaded] = useState(false);

  const {
    isLoading,
    isAuthenticated,
    user,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,

  } = useAuth0();

  const theme = useMemo(
    () => createTheme(getDesignTokens(currentTheme)),
    [currentTheme]
  );

  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
  };

  function setMode(theme) {
    localStorage.setItem("theme", theme || "light");
    const mode = theme;
    setCurrentTheme(mode);
  }

  const saveToken = useCallback(
    async () => {
      try {
        const access_token = await getToken({ getAccessTokenSilently, loginWithRedirect });
        setIsAuthLoaded(true);
        if (access_token) {
          await axios.get(React.$apis.authCookie, {
            withCredentials: true,
            headers: {
              authorizationcookie: "Bearer " + access_token,
              Authorization: "Bearer " + access_token
            }
          })
        }

      } catch (e) {
        console.log(e);
      }
    }, [getAccessTokenSilently, loginWithRedirect])



  useEffect(() => {
    if (isLoading) return;
    if (!isAuthenticated) {
      console.log("User not Authenticated, Calling loginWithRedirect")
      const currentUrl = window.location.href
      loginWithRedirect({
        appState: { returnTo: currentUrl }
      });
    }

    saveToken();
  }, [isLoading, isAuthenticated, loginWithRedirect, saveToken]);


  return isAuthenticated && isAuthLoaded ? (
    <ThemeProvider theme={theme}>
      <AppProvider>
        <div
          className="App"
          ref={scrollRef}
          style={{ backgroundColor: theme.palette.themeColor }}
        >
          <BrowserRouter>
            <Box>
              <Header
                scrollRef={scrollRef}
                isNavbarOpen={isNavBarOpen}
                handleSearchQueryChange={handleSearchQueryChange}
                searchQuery={searchQuery}
              >
                <NavBar
                  setMode={setMode}
                  user={user}
                  onNavBarExpand={() => setIsNavBarOpen(true)}
                  onNavBarCollapse={() => setIsNavBarOpen(false)}
                  logout={() => logout({ logoutParams: { returnTo: window.location.origin } })}
                />
              </Header>
            </Box>

            <AppRoutes isNavbarOpen={isNavBarOpen} />
            <Footer />
          </BrowserRouter>
        </div>
      </AppProvider>
    </ThemeProvider>
  ) : (
    <> </>
  );
}

export default App;
