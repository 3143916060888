import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  wrapper: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    border: "1px solid #A8ABC2",
    borderRadius: "5px",
    backgroundColor: ({ theme }) => theme.palette.bgColor,
    color: ({ theme }) => theme.palette.text.primary,
    boxShadow: "none",
    width: ({ width }) => width,
    minWidth: ({ minWidth }) => minWidth,
    // height: ({ height }) => height,
    minHeight: "30px",
    "@media (min-width: 1100px) and (max-width: 1300px)": {
      height: "28px",
      minHeight: "20px",
    },
    "@media (min-width: 1000px) and (max-width: 1101px)": {
      height: "25px",
      minHeight: "20px",
    },
    "@media (min-width: 900px) and (max-width: 1001px)": {
      height: "22px",
      minHeight: "20px",
    },
    "@media (min-width: 600px) and (max-width: 901px)": {
      height: "19px",
      minHeight: "19px",
    },
  },
  input: {
    marginLeft: "8px",
    flex: 1,
    fontFamily: "Rubik",
    fontSize: "0.8vw",
    fontWeight: 400,
    color: ({ theme }) => theme.palette.text.primary,
    backgroundColor: ({ theme }) => theme.palette.bgColor,
  },
  icon: {
    padding: "5px",
    color: ({ theme }) => theme.palette.text.primary,
    marginTop: "-2px",
    height: "2rem",
    maxHeight: "20px",
  },
});
