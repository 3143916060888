import { useStyles } from "./styles";
import { useTheme } from "@emotion/react";
import { Box, Button, IconButton, Stack } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import HelvetiaCompliancyCheckDetails from "./helvetiaDetails";
import constants from "./../../constants/constants.json";
import BasicTable from "components/table";
import { useNavigate, useParams } from "react-router";
import { IoMdSearch } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import Notification from "components/snackbar";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";
import { getToken } from "utils/loadAuth";
const HelvetiaCompliancyCheck = ({
  isNavbarOpen,
  disableVirtualization = false,
}) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0()
  const theme = useTheme();
  const classes = useStyles({ theme, isNavbarOpen });

  const [rows, setRows] = useState([]);
  const [openDetails, setOpenDetails] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);
  const [filename, setFilename] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const { pid, fid } = useParams();
  const defaultSort = { field: "filename", sort: "asc" };
  const [sort, setSort] = useState([defaultSort]);
  const [helvetiaPaginationModel, setHelvetiaPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const onPaginationModelChange = ({ page, pageSize }) => {
    setHelvetiaPaginationModel({
      page,
      pageSize,
    });
  };

  const onSortModelChange = (event) => {
    if (JSON.stringify(event) !== JSON.stringify(sort)) {
      setSort(event);
    }
  };

  const getIcons = useCallback(
    (status) => {
      switch (status.toLowerCase()) {
        case "yes":
          return <CheckBoxIcon className={classes.statusYes} />;
        case "no":
          return <DisabledByDefaultIcon className={classes.statusNo} />;
        default:
          return <HelpCenterIcon className={classes.unknownStatus} />;
      }
    },
    [classes]
  );

  const getEDCdata = useCallback(async () => {

    try {
      const token = await getToken({ getAccessTokenSilently, loginWithRedirect });
      if (token) {
        const res = await React.$fetch(React.$apis.systemEdcParser, {}, token)
        setIsLoading(false);
        const rowsData = res.data.map((row, index) => ({
          id: index + 1,
          ...row,
        }));
        setRows(rowsData)
      }
    } catch (error) {
      setIsLoading(false);
      console.log("Error: ", error.message);
    }

  }, [getAccessTokenSilently, loginWithRedirect]);

  const location = useLocation();
  const helvetiaQueryParams = useCallback(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const getHelvetiaTemplateName = () => {
    try {
      const helvetiaTemplateValue = helvetiaQueryParams().get("id");
      if (helvetiaTemplateValue) {
        return helvetiaQueryParams().get("id").split("/").pop();
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const tid = decodeURIComponent(getHelvetiaTemplateName());

  useEffect(() => {
    if (!tid || tid === "null") {
      setOpenDetails(false);
      navigate(`/overview/entry/${pid}/${fid}`);
    } else {
      navigate(`/overview/entry/${pid}/${fid}?id=${tid}`);
      setOpenDetails(true);
    }
  }, [fid, navigate, pid, tid]);

  useEffect(() => {
    getEDCdata();
  }, [getEDCdata]);

  const columns = [
    {
      field: "filename",
      headerName: "FILENAME",
      flex: 0.5,
    },
    {
      field: "compliancystatus",
      headerName: "COMPLIANT TO SHIP",
      flex: 0.4,
      headerAlign: "center",
      renderCell: (params) => (
        <div className={classes.compliancystatus}>
          {getIcons(params.formattedValue)}
          <p className={classes.statusText}>{params.formattedValue}</p>
        </div>
      ),
    },
    {
      field: "rulesrev",
      headerName: "REVISION RULE SET",
      flex: 0.3,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "datetimeparsed",
      headerName: "COMPUTATION DATE",
      flex: 0.4,
      headerAlign: "center",
      renderCell: (params) => (
        <div className={classes.computationdate}>
          {params.formattedValue?.split("T")[0]}
        </div>
      ),
    },
    {
      field: "button",
      headerName: "DETAILS",
      sortable: false,
      flex: 0.3,
      renderCell: (params) => (
        <Button
          data-testid="viewDetails"
          onClick={() => {
            navigate(`/overview/entry/${pid}/${fid}?id=${params.row.filename}`);
            setOpenDetails(true);
          }}
          className={classes.detailbtn}
        >
          {constants.view}
        </Button>
      ),
    },
  ];

  const onDrop = (acceptedFiles, fileRejections) => {
    if (fileRejections?.length) {
      setOpenAlert(true);
      setAlertMessage(constants.invalidFormat);
      return;
    }
    setFile(acceptedFiles[0]);
    setFilename(acceptedFiles[0].name);
  };

  const readFile = async (file) => {
    setIsLoading(true);
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      loadFileData(text);
    };
    return reader.readAsText(file);
  };

  const loadFileData = async (text) => {

    const url = React.$apis.systemEdcParser;
    try {
      const token = await getToken({ getAccessTokenSilently, loginWithRedirect })
      if (token) {
        const res = await React.$post(url, {
          filename: file?.name,
          content: text,
        },
          token
        )
        if (res.status === 200) {
          setFile(null);
          setFilename("");
          getEDCdata();
        }
      }
    } catch (error) {

      setIsLoading(false);
      console.log(error);
    }



  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "text/plain": [".txt"],
    },
  });

  const clearFile = () => {
    setFilename("");
    setFile(null);
  };

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredRows = rows.filter((row) =>
    row.filename.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const getFileName = () => {
    if (filename) {
      return filename.length > 25 ? filename.slice(0, 25) + ".." : filename;
    }
    return constants.dragDropText;
  };

  return (
    <>
      <Box
        // className={classes.titleWrapper}
        sx={{ display: openDetails ? "none" : "flex" }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ width: "100%", mb: 2, minWidth: 1000 }}
        >
          <p className={classes.title}>{constants.listEDCFiles}</p>

          <div className={classes.dragBtngroupContainer}>
            <div className={classes.searchInput}>
              <input
                data-testid="searchInput"
                type="text"
                placeholder="Search Files"
                value={searchQuery}
                onChange={handleSearch}
              />
              <div>
                <IoMdSearch />
              </div>
            </div>

            <div className={classes.dragBtngroup}>
              <div {...getRootProps()}>
                <input type="file" {...getInputProps()} />
                <Button data-testid="dropzone" className={classes.dragBtn}>
                  {getFileName()}
                </Button>
              </div>
              <IconButton
                data-testid="clearFile"
                onClick={clearFile}
                className={classes.closeIcon}
                size="small"
                style={{
                  visibility: file === null || isLoading ? "hidden" : "visible",
                }}
              >
                <RxCross2 />
              </IconButton>
              <Button
                data-testid="uploadFile"
                disabled={file == null || isLoading}
                onClick={async () => {
                  if (file != null) {
                    await readFile(file);
                  }
                }}
                className={classes.uploadBtn}
                sx={{
                  "&.Mui-disabled": {
                    color: "#c0c0c0",
                    opacity: "0.5",
                  },
                }}
              >
                {constants.uploadFile}
              </Button>
            </div>
          </div>
        </Stack>
      </Box>

      {!openDetails && (
        <Box className={classes.tableWrapper}>
          <BasicTable
            columns={columns}
            onSortModelChange={onSortModelChange}
            sortModel={sort}
            paginationModel={helvetiaPaginationModel}
            onPaginationModelChange={onPaginationModelChange}
            disableVirtualization={disableVirtualization}
            rows={filteredRows}
            disableColumnMenu={true}
            isLoading={isLoading}
            sxProps={{
              maxHeight: "75vh",
            }}
          />
        </Box>
      )}

      {openDetails && (
        <HelvetiaCompliancyCheckDetails
          setOpenDetails={setOpenDetails}
          tid={tid}
        />
      )}
      <Notification
        open={openAlert}
        setOpen={setOpenAlert}
        message={alertMessage}
        severity="info"
      />
    </>
  );
};

HelvetiaCompliancyCheck.propTypes = {
  isNavbarOpen: PropTypes.bool,
  disableVirtualization: PropTypes.bool
};
export default HelvetiaCompliancyCheck;
