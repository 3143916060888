import { Typography, Box } from "@mui/material";

export default function OverViewTitle({ title, theme }) {
  if (title === undefined) return null;
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        top: 0,
        mt: 2,
      }}
    >
      <Typography
        sx={{
          mb: 1,
          fontWeight: "700",
          fontSize: "20px",
          lineHeight: "45px",
          color: theme.palette.text.primary,
        }}
      >
        {title}
      </Typography>
    </Box>
  );
}
