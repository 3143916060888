import { createUseStyles } from "react-jss";
export const useStyles = createUseStyles({
  separator: {
    margin: 0,
    padding: 0,
  },
  nextIcon: {
    color: "#ffff",
    padding: 0,
    margin: 0,
  },
  link: {
    color: "#ffff",
    fontWeight: 100,
    textDecoration: "none",
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    fontSize: "0.8vw !important"
  },
  text: {
    color: "#ffff",
    fontWeight: 100,
    fontSize: "0.8vw !important"
  },
  productsIcon: {
    marginTop: "-3px",
    fontSize: "1vw"
  },
  productsText: {
    marginLeft: "5px",
    fontSize: "0.8vw"
  }
});