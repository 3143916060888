import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTheme } from "@emotion/react";
import { useStyles } from "./style";
import PropsTypes from "prop-types";
import { Tooltip } from "@mui/material";

export default function TakaOverviewTable({
  tableList,
  setStageFilter,
  stageFilter,
  onSetStageFilter,
}) {
  const isDarkTheme = useTheme().palette.mode === "dark";
  const theme = useTheme();
  const classes = useStyles({ theme, isDarkTheme });
  if (!tableList || tableList.length === 0) {
    return null;
  }

  const renderCellContent = (cell, isLink, onClick) => (
    <p {...(isLink ? { onClick } : {})}>{cell}</p>
  );

  const getCellStyle = (order, isLastRow, isDarkTheme) => {
    const commonStyles = {
      ...(isLastRow ? { borderBottom: "none" } : {}),
    };

    const specificStyles = {
      0: {},
      1: {},
      2: isDarkTheme ? { borderRight: "2px solid #A8ABC2" } : {},
      3: {},
      4: {
        minWidth: 110,
        ...(isDarkTheme ? { borderRight: "2px solid #A8ABC2" } : {}),
      },
      5: { minWidth: 100 },
      6: {
        minWidth: 110,
        ...(isDarkTheme ? { borderRight: "2px solid #A8ABC2" } : {}),
      },
      7: { minWidth: 100 },
      8: {
        ...(isDarkTheme
          ? { borderRight: "2px solid #A8ABC2", minWidth: 150 }
          : { minWidth: 140 }),
      },
      9: {},
      10: { maxWidth: 50, borderRight: "2px solid #A8ABC2" },
      default: { maxWidth: 50 },
    };

    return { ...commonStyles, ...specificStyles[order] };
  };

  const getClassName = (order) => {
    if (order === 0) return classes.takaWaferOrder;
    if (order === 1 || order === 2) return classes.takaBumpDie;
    if (order === 3 || order === 4) return classes.takaProb;
    if (order === 5 || order === 6) return classes.takaAssembly;
    if (order === 7 || order === 8) return classes.takaFinalTest;
    if (order === 9 || order === 10) return classes.takaTransit;
    return classes.takaAdvantest;
  };

  const renderCells = ({ cell, order, isLink, isLastRow }) => {
    const stageFilterClickHandler = () => {
      setStageFilter({
        ...stageFilter,
        id: order,
        qty: cell,
      });
      onSetStageFilter();
    };

    return (
      <TableCell
        key={order}
        variant="head"
        className={getClassName(order)}
        style={getCellStyle(order, isLastRow, isDarkTheme)}
      >
        {renderCellContent(cell, isLink, stageFilterClickHandler)}
      </TableCell>
    );
  };

  return (
    <TableContainer className={classes.tablebContainer} component={Paper}>
      <Table
        sx={{ width: "100%", padding: 2, height: "35vh" }}
        aria-label="Summary Table"
        stickyHeader
      >
        <TableHead>
          <TableRow
            style={{
              borderBottom: "2px solid #A8ABC2",
            }}
          >
            <TableCell className={classes.tableHeadTaka} variant="head">
              SUPPLIER
            </TableCell>
            <TableCell
              className={classes.tableHeadTaka}
              variant="head"
              style={
                {
                  // maxWidth: "5px !important",
                }
              }
            >
              TSMC/GUC
            </TableCell>

            <TableCell
              className={classes.tableHeadTaka}
              align="center"
              colSpan={10}
              variant="head"
            >
              AMKOR
            </TableCell>

            <TableCell
              align="center"
              colSpan={6}
              className={classes.tableHeadTaka}
              style={{
                borderRight: "none",
              }}
              variant="head"
            >
              ADVANTEST
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* Manufacturing step */}
          <TableRow
            sx={{
              borderBottom: "2px solid #A8ABC2",
            }}
          >
            <TableCell
              className={classes.takaFirstCol}
              style={{
                background: "none",
                maxWidth: 50,
              }}
              variant="head"
            >
              Manufacturing step
            </TableCell>

            <TableCell className={classes.takaWaferOrder} variant="head">
              Wafer Orders
            </TableCell>

            <TableCell variant="head" className={classes.takaBumpDie}>
              Bump Die Bank
            </TableCell>
            <TableCell
              variant="head"
              className={classes.takaBumpDie}
              style={{
                ...(isDarkTheme ? { borderRight: "2px solid #A8ABC2" } : {}),
              }}
            >
              Bump
            </TableCell>
            <TableCell variant="head" className={classes.takaProb}>
              Probe Die Bank
            </TableCell>
            <TableCell
              variant="head"
              className={classes.takaProb}
              style={{
                ...(isDarkTheme ? { borderRight: "2px solid #A8ABC2" } : {}),
              }}
            >
              Probe
            </TableCell>
            <TableCell variant="head" className={classes.takaAssembly}>
              Assembly Die Bank
            </TableCell>
            <TableCell
              variant="head"
              className={classes.takaAssembly}
              style={{
                ...(isDarkTheme ? { borderRight: "2px solid #A8ABC2" } : {}),
              }}
            >
              Assembly
            </TableCell>
            <TableCell variant="head" className={classes.takaFinalTest}>
              Final Test Die Bank
            </TableCell>
            <TableCell
              variant="head"
              className={classes.takaFinalTest}
              style={{
                ...(isDarkTheme ? { borderRight: "2px solid #A8ABC2" } : {}),
              }}
            >
              Final Test
            </TableCell>
            <TableCell variant="head" className={classes.takaTransit}>
              Transit
            </TableCell>
            <TableCell
              variant="head"
              className={classes.takaTransit}
              style={{
                borderRight: "2px solid #A8ABC2",
              }}
            >
              Transit
            </TableCell>

            <TableCell variant="head" className={classes.takaAdvantest}>
              Stock On Hand
            </TableCell>
            <TableCell variant="head" className={classes.takaAdvantest}>
              Stock On Hand
            </TableCell>
            <TableCell variant="head" className={classes.takaAdvantest}>
              Sub Total Liabilities
            </TableCell>
            <TableCell variant="head" className={classes.takaAdvantest}>
              Sub Total WIP
            </TableCell>
            <TableCell variant="head" className={classes.takaAdvantest}>
              Sub Total Buffer
            </TableCell>
            <TableCell variant="head" className={classes.takaAdvantest}>
              Total
            </TableCell>
          </TableRow>

          {/* Manufacturing site */}
          <TableRow
            key={"Manufacturing site"}
            sx={{
              borderBottom: "2px solid #A8ABC2 !important",
            }}
          >
            <TableCell
              component="th"
              scope="row"
              className={classes.takaFirstCol}
            >
              Manufacturing site
            </TableCell>

            {tableList?.["Manufacturing site"].map((m, i) =>
              renderCells({
                cell: m,
                order: i,
              })
            )}
          </TableRow>
          {/* Unit of measure */}
          <TableRow
            key={"Unit of measure"}
            sx={{
              borderBottom: "2px solid #A8ABC2 !important",
            }}
          >
            <TableCell
              component="th"
              scope="row"
              className={classes.takaFirstCol}
            >
              Unit of measure
            </TableCell>

            {tableList?.["Unit of measure"].map((m, i) =>
              renderCells({
                cell: m,
                order: i,
              })
            )}
          </TableRow>

          {/* ADV Item Number */}
          <TableRow
            key={"ADV Item Number"}
            sx={{
              borderBottom: "2px solid #A8ABC2 !important",
            }}
          >
            <TableCell
              component="th"
              scope="row"
              className={classes.takaFirstCol}
            >
              ADV Item Number
            </TableCell>

            {tableList?.["ADV Item Number"].map((m, i) =>
              renderCells({
                cell: m,
                order: i,
              })
            )}
          </TableRow>

          {/* Units */}
          <TableRow
            key={"Units"}
            sx={{
              borderBottom: "2px solid #A8ABC2 !important",
              textDecoration: "underline",
              cursor: "pointer",
              color: isDarkTheme ? "#00BFFF" : "blue",
              textDecorationThickness: "1.3px",
              textUnderlineOffset: "2px",
            }}
          >
            <Tooltip title="Click for more details">
              <TableCell
                component="th"
                scope="row"
                className={classes.takaFirstCol}
                onClick={() => {
                  setStageFilter({ ...stageFilter, id: -1, qty: "Units" });
                  onSetStageFilter();
                }}
              >
                Units
              </TableCell>
            </Tooltip>

            {tableList?.["Units"]?.map((m, i) =>
              renderCells({
                isLink: true,
                cell: (
                  <Tooltip title="Click for more details">
                    <span className={classes.tootip}>{m}</span>
                  </Tooltip>
                ),
                order: i,
              })
            )}
          </TableRow>

          {/* Value (Single unit) */}
          <TableRow
            key={"Value (Single unit)"}
            sx={{
              borderBottom: "2px solid #A8ABC2 !important",
            }}
          >
            <TableCell
              component="th"
              scope="row"
              className={classes.takaFirstCol}
            >
              Value (Single unit)
            </TableCell>

            {tableList?.["Value (Single unit)"]?.map((m, i) =>
              renderCells({
                cell: m,
                order: i,
              })
            )}
          </TableRow>

          {/* Value (Total) */}
          <TableRow key={"Value (Total)"} style={{}}>
            <TableCell
              component="th"
              scope="row"
              className={classes.takaFirstCol}
              style={{
                borderBottom: "none",
              }}
            >
              Value (Total)
            </TableCell>

            {tableList?.["Value (Total)"]?.map((m, i) =>
              renderCells({
                cell: m,
                order: i,
                isLastRow: true,
              })
            )}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

TakaOverviewTable.propTypes = {
  tableList: PropsTypes.object,
  setStageFilter: PropsTypes.func,
  stageFilter: PropsTypes.object,
  onSetStageFilter: PropsTypes.func,
};
