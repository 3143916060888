import Box from "@mui/material/Box";
import { useStyles } from "../style";
import { useTheme } from "@emotion/react";

export default function CustomTabPanel(props) {
  const { children, value, index, isNavbarOpen, ...other } = props;

  const theme = useTheme();
  const classes = useStyles({ theme, isNavbarOpen });
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={classes.tab}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}
