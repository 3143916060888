/**
 * Warning, this file will be overwritten while building
 * baseUrl will be replace by aws-exports.js
 *
 * for example, when build branch lijing, some variables will changed to:
 * baseUrl = 'https://zsh16kk6j1.execute-api.eu-central-1.amazonaws.com/lijing';
 */

// To do
// To be changed before merging with master. Only to test current changes in Dynamo DB
export let apiUrl = "";

switch (process.env.REACT_APP_AMPLIFY_ENV) {
  case "master":
    apiUrl = "https://api.glasswall.advantest.com";
    break;
  case "prod":
    apiUrl = "https://api.glasswall.advantest.com";
    break;
  case "staging":
    apiUrl = "https://api.staging.glasswall.advantest.com";
    break;
  case "dev":
    apiUrl = "https://api.demo.glasswall.advantest.com";
    break;
  case "QA":
    apiUrl = "https://api.qa.glasswall.advantest.com";
    break;
  case "siemens":
    apiUrl = "https://api.siemens.glasswall.advantest.com";
    break;
  default:
    apiUrl = "https://api.glasswall.advantest.com";
}

export const timeout = 30000;
export const baseUrl = apiUrl;
export const overViewUrl = apiUrl;
export const webPlayerBaseUrl = apiUrl;
export const spotfireBaseUrl = webPlayerBaseUrl + "/spotfire/wp";
export const cookieDomain = ".glasswall.advantest.com";
